import API from '@/api/manager/apply/index.js';
import { nprogressStart, nprogressClose } from "@/plugins/nprogress.js";
export default {
        // 页面名称
        name: "colleague",
        data()
        {
            return{
                list:[],
                status:0,
                managerstatusList:[
                    {
                        id:-1,
                        name:'全部'
                    },
                    {
                        id:0,
                        name:'未处理'
                    },
                    {
                        id:1,
                        name:'已处理'
                    },
                ],
                page:1,
                pageSize:10,
                total:0,

            }
        },
        /**
         * @name: 生命周期创建
         * @desc: 页面创建时获取数据
         * @author: camellia
         * @date: 2024-02-05
         */
        created()
        {
            let self=this;
            self.getData()
        },
        methods:
        {
            updateApplyStatus(row)
            {
                console.log(row);
                let self=this;
                // 请求参数
                let data = {
                    id : row.id
                };
                // 删除请求
                self.$confirm('确认已经处理完成吗?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    API.updateApplyStatus(data)
                    .then((result)=>{
                        if(result.code > 0 )
                        {
                            self.getData();
                            this.$Tips({
                                // 消息提示内容
                                message:result.msg,
                                // 消息提示类型（success-成功,warning-警告/失败）
                                messageType:'success',
                                displayTime:1500
                            })
                        }
                    })  
                }).catch(() => {
                    // Message.info('已取消');        
                });     
            },
            indexMethod(index) 
            {
                let currentPage = this.page; // 当前页码
                let pageSize = this.pageSize; // 每页条数
                return (index + 1) + (currentPage - 1) * pageSize; // 返回表格序号
            },
            handleSearchColleague() 
            {
                let self = this;
                console.log(self.status)
                self.getData()
            },
            getData()
            {
                try 
                {
                    let self=this;
                    // 请求参数
                    let data = {
                        status : self.status,
                        page:self.page,
                        pageSize:self.pageSize,
                    };
                    nprogressStart();
                    self.$Loading.show();
                    API.getApplyList(data)
                        .then((result)=>{
                            nprogressClose();
                            self.$Loading.hide();
                            if(result.code > 0 )
                            {
                                self.list = result.list
                                self.total = result.list.length - 0 
                            }
                        })                
                } 
                catch (error) 
                {
                    // Message.error('系统错误') 
                }
            },
        }       
};