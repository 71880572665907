import { request } from "@/request/http";

export default 
{
  getApplyList: (data) => {
    return request("POST", `/index.php/applyAdmin/getApplyList`, data);
  },

  updateApplyStatus: (data) => {
    return request("POST", `/index.php/applyAdmin/updateApplyStatus`, data);
  },
  
  
};